import React from "react"
import "../index.scss"

import Layout from "src/components/layout"
import Head from "../../components/Head"

import ClientSticker from "src/components/App/ClientSticker/ClientSticker"
import Button from "../../components/common/Button/Button"
import { graphql, useStaticQuery } from "gatsby"
import {useTranslation} from "react-i18next"
import BRANDS from "../../brands";

const AppMain = ({ location }) => {

  const { t, i18n } = useTranslation()
  const data = useStaticQuery(graphql`
    query appPageClientsLtEn {
      site {
        siteMetadata {
          countries {
            lt {
              hotLine {
                tel
                title
                telephone
                telephoneTitle
              }
            }
          }
        }
      }
    }
  `)

  return (
    <Layout
      hotLine={data.site.siteMetadata.countries.lt.hotLine}
      location={location}
      country="lt_en"
    >
      <Head
        lang="lt_en"
        title={t('head.app.title', { brand: BRANDS.SPORTBENEFIT.appTitle } )}
        slug="/app/"
        apps={true}
      />
      <section className="main-page">
        <div className="buttons-wrapper">
          <a
            className="button blue"
            href="https://play.google.com/store/apps/details?id=eu.sportbenefit.a"
          >
            { t("app.android.title") }
          </a>
        </div>
        <div className="buttons-wrapper">
          <a
            className="button blue"
            href="https://appgallery.huawei.com/app/C106213183"
          >
            { t("app.huawei.title") }
          </a>
        </div>
        <div className="buttons-wrapper">
          <a
            className="button blue"
            href="https://apps.apple.com/us/app/sportbenefit/id1555414487"
          >
            { t("app.ios.title") }
          </a>
        </div>
        <form
          method="get"
          action={
            "media/sportbenefiteu-release.apk?" +
            "created=" +
            new Date().toDateString()
          }
        >
          <Button color="blue" type="submit">
            { t("app.android.from_site_title") }
          </Button>
        </form>
      </section>
      <ClientSticker language={i18n.language} />
    </Layout>
  )
}

export default AppMain
